<template>
    <div class="card-wrapper" :style="{'background-image': 'url(' + background + ')'}">
      <div @click="act1" :class="{'active2': active1}" class="about-card fist-c">
        <img class="icon-img" :src="img1" />
        <h4 class="hover-text"><span v-html="card1h1"></span> <span style="margin-left: 5px"><slot /></span></h4>
        <div class="border-division"></div>
        <p>{{card1p}}</p>
        <img :class="{'icon-card1':active1}" class="m-hide" :src="cardImg1" alt="" />
      </div>
      <div @click="act2" :class="{'active2': active2}" class="about-card ">
        <img class="icon-img" :src="img2" />
        <h4 class="hover-text">{{card2h1}}</h4>
        <div class="border-division"></div>
        <h4 class="unhover-text">Pesquise</h4>
        <p>{{card2p}}</p>
        <img :class="{'icon-card2':active2}" class="m-hide" :src="cardImg2" alt="" />
      </div>
      <div @click="act3" :class="{'active2': active3}"  class="about-card third-c">
        <img class="icon-img" :src="img3" />
        <h4 class="hover-text">{{card3h1}}</h4>
        <div class="border-division"></div>
        <h4 class="unhover-text">Seleccione</h4>
        <p>{{card3p}}</p>
        <img :class="{'icon-card3':active3}" class="m-hide" :src="cardImg3" alt="" />
      </div>
      <div @click="act4" :class="{'active2': active4}"  class="about-card foth-c">
        <img class="icon-img" :src="img4" />
        <h4 class="hover-text">{{card4h1}}</h4>
        <h4 class="unhover-text">Contrate</h4>
        <div class="border-division"></div>
        <p>{{card4p}}</p>
        <img :class="{'icon-card4':active4}" class="m-hide" :src="cardImg4" alt="" />
      </div>
    </div>
</template>

<script>
export default {
  name: 'AboutCardsTemplate',
  data () {
    return {
      active1: true,
      active2: false,
      active3: false,
      active4: false
    }
  },
  methods: {
    act1 () {
      // this.active1 = !this.active1
      this.active2 = false
      this.active3 = false
      this.active4 = false
    },
    act2 () {
      this.active2 = !this.active2
      if (this.active2) {
        this.active1 = true
      } else {
        this.active3 = false
        this.active4 = false
      }
    },
    act3 () {
      this.active3 = !this.active3
      if (this.active3) {
        this.active1 = true
        this.active2 = true
      } else {
        this.active4 = false
      }
    },
    act4 () {
      this.active4 = !this.active4
      if (this.active4) {
        this.active1 = true
        this.active2 = true
        this.active3 = true
      }
    }
  },
  props: {
    activeTogle: {
      type: Boolean,
      default: () => false
    },
    cardImg1: {
      type: String
    },
    cardImg2: {
      type: String
    },
    cardImg3: {
      type: String
    },
    cardImg4: {
      type: String
    },
    card1h1: {
      type: String
    },
    card1p: {
      type: String
    },
    card2h1: {
      type: String
    },
    card2p: {
      type: String
    },
    card3h1: {
      type: String
    },
    card3p: {
      type: String
    },
    card4h1: {
      type: String
    },
    card4p: {
      type: String
    },
    img1: {
      type: String
    },
    img2: {
      type: String
    },
    img3: {
      type: String
    },
    img4: {
      type: String
    },
    background: {
      type: String,
      default: () => require('../../assets/img/about-arrows.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
.about-card {
  p {
    height: 136px;
  }
  .hover-text {
    min-height: 55px;
  }
}
</style>
