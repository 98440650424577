<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 33.031 40.553"
  >
    <g :fill="color">
      <path
        class="a"
        d="M77.723,37.937H75.107V5.887A1.308,1.308,0,0,0,73.8,4.579H63.987V1.308A1.308,1.308,0,0,0,62.679,0H53.6A1.308,1.308,0,0,0,52.3,1.308v3.27H51.233a1.308,1.308,0,0,0-1.308,1.308v32.05H47.308a1.308,1.308,0,1,0,0,2.616H77.723a1.308,1.308,0,1,0,0-2.616ZM54.912,2.616h6.459V4.579H54.912ZM59.9,37.937v-5.56h5.233v5.56Zm7.849,0v-5.56h.572a1.308,1.308,0,0,0,0-2.616H56.711a1.308,1.308,0,0,0,0,2.616h.572v5.56H52.541V7.195H72.49V37.937Zm-7.113-26.9a1.308,1.308,0,0,1-1.308,1.308H57.855a1.308,1.308,0,1,1,0-2.616h1.472A1.308,1.308,0,0,1,60.635,11.038Zm7.767,0a1.308,1.308,0,0,1-1.308,1.308H65.622a1.308,1.308,0,1,1,0-2.616h1.472A1.308,1.308,0,0,1,68.4,11.038Zm-7.767,4.906a1.308,1.308,0,0,1-1.308,1.308H57.855a1.308,1.308,0,1,1,0-2.616h1.472A1.308,1.308,0,0,1,60.635,15.943Zm7.767,0a1.308,1.308,0,0,1-1.308,1.308H65.622a1.308,1.308,0,1,1,0-2.616h1.472A1.308,1.308,0,0,1,68.4,15.943Zm-7.767,4.906a1.308,1.308,0,0,1-1.308,1.308H57.855a1.308,1.308,0,1,1,0-2.616h1.472A1.308,1.308,0,0,1,60.635,20.849Zm7.767,0a1.308,1.308,0,0,1-1.308,1.308H65.622a1.308,1.308,0,1,1,0-2.616h1.472A1.308,1.308,0,0,1,68.4,20.849Zm-7.767,4.906a1.308,1.308,0,0,1-1.308,1.308H57.855a1.308,1.308,0,0,1,0-2.616h1.472A1.308,1.308,0,0,1,60.635,25.754Zm7.767,0a1.308,1.308,0,0,1-1.308,1.308H65.622a1.308,1.308,0,0,1,0-2.616h1.472A1.308,1.308,0,0,1,68.4,25.754Z"
        transform="translate(-46)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconCompany',
  props: {
    color: {
      type: String,
      default: () => '#fff'
    },
    width: {
      type: String,
      default: () => '28'
    },
    height: {
      type: String,
      default: () => '35'
    }
  }
}
</script>
