<template>
    <div class="about-toggle">
        <div @click="ProfissionalCallFunctions(true)" class="profissional" :class="{'profissional-active':ProfissionalActive, 'active':ProfissionalActive}">
            <div class="wrapper-icon">
                <icon-profissional :color="ActiveProfissionalIconColor" />
            </div>
            <div class="wrapper-data">
                <p class="title">Profissional</p>
                <p class="desc">Como tornar-me um Profissional?</p>
            </div>
        </div>
        <div @click="CompanyCallFunctions(false)"  class="company" :class="{'company-active':CompanyActive, 'active':CompanyActive}">
            <div class="wrapper-icon">
                <icon-company :color="ActiveCompanyIconColor" />
            </div>
            <div class="wrapper-data">
                <p class="title">Empresa</p>
                <p class="desc">Como posso contratar um Profissional?</p>
            </div>
        </div>
    </div>
</template>

<script>
import IconCompany from '@/components shared/icon-company'
import IconProfissional from '@/components shared/icon-profissional'
export default {
  name: 'AboutToggle',
  components: {
    'icon-company': IconCompany,
    'icon-profissional': IconProfissional
  },
  data () {
    return {
      ActiveCompanyIconColor: '#4B335A',
      ActiveProfissionalIconColor: '#fff',
      CompanyActive: false,
      ProfissionalActive: true
    }
  },
  methods: {
    toogleCompany () {
      this.CompanyActive = true
      this.ProfissionalActive = false
      this.ActiveCompanyIconColor = '#FFF'
      this.ActiveProfissionalIconColor = '#4B335A'
    },
    toogleProfissional () {
      this.CompanyActive = false
      this.ProfissionalActive = true
      this.ActiveCompanyIconColor = '#4B335A'
      this.ActiveProfissionalIconColor = '#FFF'
    },
    ProfissionalCallFunctions (state) {
      this.$emit('toggle-profissional', state)
      this.toogleProfissional()
    },
    CompanyCallFunctions (state) {
      this.$emit('toggle-profissional', state)
      this.toogleCompany()
    }
  }
}
</script>

<style lang="scss" scoped>
    .about-toggle{
        width: 100%;
        height: 70px;
        display: flex;
        .company-active{
            background: #26A0A5!important;
        }
       .profissional-active{
            background: #6A4C7C!important;
       }
       .active{
            .title{
                color: #fff!important;
                @media(max-width:768px){
                    font-weight: lighter!important;
                }
            }
            .desc{
                color: #fff!important;
            }
        }
        .company{
            border-radius:0 10px 10px 0!important;
        }
        .company,
        .profissional{
            flex: 1;
            display: flex;
            cursor: pointer;
            border-radius: 10px 0 0 10px;
            background: #FACA63;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 3px 12px #00000029;
            @media(max-width:768px){
                height: 90px;
            }
            .wrapper-icon{
                width: 39px;
                height: 39px;
            }
            .wrapper-data{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .title{
                    text-align: start;
                    font-size: 14px;
                    font-weight: bold;
                    color: #4B335A;
                    @media(min-width:768px){
                        font-size: 16px;
                    }
                }
                .desc{
                    text-align: start;
                    font-size: 12px;
                    font-weight: lighter;
                     color: #4B335A;
                     @media(min-width:768px){
                        font-size: 14px;
                    }
                      @media(max-width:768px){
                        font-size: 10px!important;
                        max-width: 110px;
                    }
                }
            }
        }
    }
</style>
