<template>
  <div class="about">
    <h2>
      <strong>
       A Plataforma de consulta e contratação temporária de profissionais capacitados
      </strong>
    </h2>
    <p class="about-text">
          A RISE WORK é a solução que pretende revolucionar o mercado de trabalho em Angola.
      Através desta plataforma conectamos o Profissional certo à empresa certa, promovendo o emprego e ajudando as empresas a potenciar o seu negócio.
      Aqui estão disponíveis os melhores profissionais, identificados, capacitados e preparados pela RISE WORK, para serem contratados de forma temporária ou permanente, através de um processo simples, ágil e 100% digital.
      <br>Uma única plataforma, múltiplas oportunidades!
    </p>
        <div class="toggle-container">
          <about-toggle @toggle-profissional="activeProfissional"/>
        </div>
        <about-cards-compay
          v-if="!show"
          :cardImg1="require(`@/assets/img/${cardComImg1}`)"
          :cardImg2="require(`@/assets/img/${cardComImg2}`)"
          :cardImg3="require(`@/assets/img/${cardComImg3}`)"
          :cardImg4="require(`@/assets/img/${cardComImg4}`)"
          card1h1="Registar-me na plataforma"
          card1p="Através de um preenchimento simples e online, identifica os dados gerais da empresa que representa."
          card2h1="Pesquiso os perfis dos profissionais, de acordo com os critérios que desejar"
          card2p="Através do nosso motor de pesquisa, pode fazer uma pesquisa avançada de acordo com o critério que valoriza, bem como ver os novos perfis na plataforma."
          card3h1="Selecciono os profissionais que tenho interesse."
          card3p="Identifica os potenciais profissionais que correspondem ao perfil que procura, marcando-os nos seus preferidos."
          card4h1="Manifesto interesse e submeto um pedido à RISE para dar seguimento ao processo de selecção."
          :card4p="`${textCard}`"
          :img1="require('@/assets/img/Icon (1).svg')"
          :img2="require('@/assets/img/Search1.svg')"
          :img3="require('@/assets/img/hr.svg')"
          :img4="require('@/assets/img/team.svg')"
          :background="require(`../../assets/img/${backgroundAboutCom}`)"
        >
        <router-link to='/formempresa'>RISE.</router-link>
        </about-cards-compay>
        <about-cards-profissional
          v-if="show"
          :cardImg1="require(`@/assets/img/${cardProImg1}`)"
          :cardImg2="require(`@/assets/img/${cardProImg3}`)"
          :cardImg3="require(`@/assets/img/${cardProImg2}`)"
          :cardImg4="require(`@/assets/img/${cardProImg4}`)"
          card1h1="Registar-me"
          card1p="Através de um preenchimento simples e online, forneces os teus dados pessoais, habilitações académicas e a tua experiência profissional. Queremos conhecer-te!"
          card2h1="Participar com aproveitamento na formação “Profissional +”"
          card3h1="Dar-me a conhecer, através de uma entrevista profissional e especializada"
          card4h1="O meu perfil está disponível na plataforma para as empresas contratarem os meus serviços!"
          :img1="require('@/assets/img/Icon (1).svg')"
          :img2="require('@/assets/img/advice.svg')"
          :img3="require('@/assets/img/work.svg')"
          :img4="require('@/assets/img/Grupo 1291.svg')"
          :background="require(`../../assets/img/${backgroundAboutPro}`)"
        >
        <router-link to='/Form'>aqui</router-link>
        </about-cards-profissional>
        <div class="container-footer">
          <h3>Porquê recorrer à RISE WORK?</h3>
          <ul class="items" v-if="isShowAboutRise">
            <li :class="[show ? 'profissional' : 'bussiness']" v-for="(item, index) of aboutRise[aboutRise.toogle]" :key="index"><span class="badge">{{index + 1}}</span>
            <p>{{item}}</p>
            </li>
          </ul>
          <div class="container-button">
            <img
            class="icon-arrow-up"
            @click="isShowAboutRise = !isShowAboutRise"
            :class="[aboutRise.toogle, isShowAboutRise ? 'active' : '']"
            src="@/assets/img/up-arrow2.svg"
            alt="arrow-up" />
          </div>
        </div>
    </div>
</template>

<script>
import AboutToggle from './AboutToggle'
import AboutCardsTemplate from './AboutCardsTemplate'
import AboutCardsTemplate2 from './AboutCardsTemplate2'
export default {
  name: 'AboutComponent',
  components: {
    'about-toggle': AboutToggle,
    'about-cards-compay': AboutCardsTemplate2,
    'about-cards-profissional': AboutCardsTemplate
  },
  mounted () {
    const ScreenSize = window.screen.width
    if (ScreenSize < 560) {
      this.backgroundAboutPro = 'about-page-mobile.svg'
      this.backgroundAboutCom = 'about-page-mobile.svg'
      this.cardProImg1 = 'icone_lilas_1.svg'
      this.cardProImg2 = 'icone_lilas_2.svg'
      this.cardProImg3 = 'icone_lilas_1.svg'
      this.cardProImg4 = 'icone_lilas_3.svg'
      this.cardComImg1 = 'icone_azul_1.svg'
      this.cardComImg2 = 'icone_azul_2.svg'
      this.cardComImg3 = 'icone_azul_1.svg'
      this.cardComImg4 = 'icone_azul_3.svg'
    }
  },
  data () {
    return {
      show: true,
      state: true,
      cardProImg1: 'icone_lilas_1.svg',
      cardProImg2: 'icone_lilas_2.svg',
      cardProImg3: 'icone_lilas_2.svg',
      cardProImg4: 'icone_lilas_3.svg',
      cardComImg1: 'icone_azul_1.svg',
      cardComImg2: 'icone_azul_2.svg',
      cardComImg3: 'icone_azul_2.svg',
      cardComImg4: 'icone_azul_3.svg',
      backgroundAboutCom: 'about-arrows.svg',
      backgroundAboutPro: 'about-arrows2.svg',
      textCard: 'Seleccione o botão “saber mais” para obter informações sobre o Profissional. A RISE WORK apresenta o perfil detalhado e as condições contratuais e agendará uma entrevista para que possa conhecer e aprovar o Profissional.',
      isShowAboutRise: false,
      aboutRise: {
        toogle: 'profissional',
        profissional: [
          'Exposição do teu perfil a potenciais oportunidades de trabalho nos mais diversos sectores',
          'Oportunidade de abraçares novos desafios que te dêem a experiência necessária para cresceres a nível profissional',
          'Capacitação para o mercado de trabalho, através de formações certificadas, uma entrevista profissional e especializada',
          'Avaliação realizada por uma equipa especializada para identificação e desenvolvimento das tuas competências'
        ],
        bussiness: [
          'Contratação de recursos especializados a um preço ajustado',
          'Rapidez no processo de contratação',
          'Flexibilidade na gestão dos recursos humanos',
          'Optimização dos custos relativos a processos de gestão administrativos',
          'Manutenção da estrutura fixa de colaboradores',
          'Apoio de um Gestor de Cliente dedicado garantindo uma verdadeira relação de parceria',
          'Aposta séria e convicta na formação, remuneração e felicidade dos nossos quadros'
        ]
      }
    }
  },
  methods: {
    activeProfissional (state) {
      this.show = state
      this.state = state
      this.aboutRise.toogle = state ? 'profissional' : 'bussiness'
      this.isShowAboutRise = false
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  margin-top: 20px;
  text-align: center;
}
.about{
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 5%;
  @media(max-width:768px){
    padding: 0!important;
    height: auto;
  }
  .about-text {
    text-align: center;
    margin: 20px 0;
    line-height: 1.2em;
    font-size: 16px;
    width: 90%;
  }
  .toggle-container{
    width: 90%;
    height: auto;
    max-width: 1336px;
  }
  .container-footer {
    margin: 40px 0;
    width: 100%;
    h3 {
      color: #4B335A;
      text-align: center;
      font-size: 22px;
      margin-bottom: 30px;
    }
    .items {
      display: flex;
      padding-left: 0;
      flex-wrap: wrap;
      margin: 0 auto;
      flex-direction: column;
      justify-content: center;
      width: 92%;
      @media screen and (min-width: 768px) {
        flex-direction: row;
        width: 100%;
      }
      li {
        color: #4B335A;
        display: flex;
        flex: 98%;
        border: 1px solid;
        margin: 1%;
        p {
          font-size: 14px;
        }
        @media screen and (min-width: 768px) {
          flex: 48%;
          width: 48%;
          max-width: 48%;
          p {
            font-size: 16px;
          }
        }
        padding: 10px;
        border-radius: 50px;
        align-items: center;
        &.profissional {
          .badge {
           background: #4B335A;
          }
        }
        &.bussiness {
          .badge {
            background: #26A0A5;
          }
        }
        .badge {
          margin-right: 12px;
          min-width: 38px;
          height: 38px;
          font-size: 18px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          color: #fafafa;
        }
      }
    }
    .container-button {
      text-align: center;
      margin-top: 40px;
      .icon-arrow-up {
        cursor: pointer;
        transition: transform .3s 0s ease-out;
        &:active {
          opacity: 0.6;
        }
        &.active {
          position: relative;
          transform: rotate(180deg);
        }
      }
    }
  }
 }
</style>
