<template>
  <div class="about-main">
    <about-component/>
  </div>
</template>

<script>
import AboutComponet from '@/components/About/AboutComponent'

export default {
  name: 'About',
  components: {
    'about-component': AboutComponet
  }
}
</script>

<style  lang="scss">
.about-main{
  width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  background: #fc0;
  height: auto;
  box-sizing: border-box;
  .border-division {
    margin: 4px 0;
  }
}
@media(max-width: 1440px) and (min-width:768px ){
  .about-card{
    width: 289px!important;
    height: 289px!important;
    margin-top:33px!important;
  }
  .icon-card1,
  .icon-card2,
  .icon-card3,
  .icon-card4{
    display: none!important;
  }
}
@media(max-width: 768px){
  .two2{
    .icon-card2{
      transform: rotate(360deg) scaleX(-1);
    }
  }
  .tree3{
    .icon-card3{
      transform: rotate(360deg) scaleX(-1);
    }
  }
}
@media(min-width: 768px){
  .icon-card1,
  .icon-card2,
  .icon-card3,
  .icon-card4{
  //  display: none;
   width: auto;
    z-index: unset!important;
  }
  .icon-card1{
    z-index: -1!important;
  }
  .icon-card2{
    transform: rotate(270deg) scaleX(-1);
    left: 79px!important;
    top: 30px!important;
  }
  .icon-card3{
    transform: rotate(270deg) ;
    left: 80px!important;
    top: -112px!important;
  }
  .icon-card4{
    transform: rotate(270deg) scaleX(-1);
    left: 59px!important;
    top: 61px!important;
  }
}
.m-hide{
  display: none;
}
.icon-card1{
  position: absolute;
  background: transparent!important;
  background-color: transparent!important;
  left: -17px;
  top: -14px;
  width: 167px!important;
  height: 166px;
  z-index: -1;
  display: initial;
}
.icon-card2{
  position: absolute;
  background: transparent!important;
  background-color: transparent!important;
  right: -16px;
  top: -15px;
  width: 167px!important;
  z-index: -1;
  display: initial;
}
.icon-card3{
  position: absolute;
  background: transparent!important;
  background-color: transparent!important;
  left: -17px;
  top: -16px;
  width: 167px!important;
  z-index: -1;
  display: initial;
}
.icon-card4{
   position: absolute;
  background: transparent!important;
  background-color: transparent!important;
  right: -15px;
  top: -15px;
  width: 157px!important;
  z-index: -1;
  display: initial;
}
.active1,
.active2{
  img.icon-img{
    display: initial!important
  }
  h4.unhover-text{
    display: none!important;
  }
  .icon-card1,
    .icon-card2,
    .icon-card3,
    .icon-card4{
      background: transparent!important;
    }
   background-color: #FFFFFF;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)!important;
    img{
      align-items: center!important;
      align-content: center!important;
      background-color: #FFFFFF!important;
    }
    p{
      visibility: visible!important;
      display: unset!important;
      text-align: center!important;
      margin-bottom: 10px!important;
      opacity: 0.55!important;
      color: #342041!important;
    }
    h4{
      display: flex!important;
      color:#1C8F93!important;
      text-align: center!important;
      align-items: initial!important;
      opacity: 1!important;
      letter-spacing: 0!important;
    }
    .border-division{
      display: flex!important;
      justify-content: center!important;
      align-items: center!important;
      width: 90%!important;
      height: 0!important;
      border: 0.5px solid #342041!important;
      opacity: 1!important;
    }
}
.card-wrapper{
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0;
    width: 100%;
    max-width: 1382px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    @media(max-width:768px){
      margin-top: 50px;
      margin-bottom: 50px;
      flex-direction: column;
    }
  }
  .fist-c{
    margin-left:-8px!important ;
    margin-right: 16px!important;
    @media(max-width:568px){
      margin-top:14px!important;
      margin-left:0px!important ;
      margin-right: 0px!important;
    }
  }
  .third-c{
    margin-left: 14px!important;
    @media(max-width:568px){
      margin-left:0px!important ;
      margin-right: 0px!important;
    }
  }
  .foth-c{
    margin-right: -8px!important;
    @media(max-width:568px){
      margin: 30px 0px 17px 0px!important;
    }
  }
  .about-card{
    cursor: pointer;
     display: flex;
     position: relative;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     border-radius: 20px;
     width: 276px;
     height: 276px;
     padding: 5px;
     margin: 30px  15px 33px 15px;
     @media(min-width:768px){
       p{
         font-size: 14px!important;
       }
       h4{
         font-size: 16px;
       }
     }
     @media(max-width:768px){
       margin: 30px  15px 52px 15px;
       width: 276px!important;
       height: 276px!important;
        z-index: 221!important;
     }
      h4{
        color:  #D08D00;
        text-align: center;
        letter-spacing: 0;
        font-size: 14px;
     }
     h4.hover-text{
       display: none;
     }
     h4.unhover-text{
       text-transform: uppercase;
       display: initial;
     }
     p{
       font-size: 10px;
       color: #FDB00C;
       visibility: hidden;
       display: none;
     }
     img.icon-img{
       display: none;
     }
     img{
       background: #fc0;
       width: 70px;
       height: auto;
      //  visibility: hidden;
     }
  }
  .about-card:hover{
    background: #FFFFFF;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    img.icon-img{
       display: initial!important;
     }
    h4.hover-text{
       display: initial;
     }
     h4.unhover-text{
       display: none;
     }
    img{
      align-items: center;
      align-content: center;
      background: #FFFFFF;
    }
    p{
      visibility: visible;
      display: unset;
      text-align: center;
      margin-bottom: 10px;
      opacity: 0.55;
      color: #342041;
    }
    h4{
      display: flex;
      color:#1C8F93;
      text-align: center;
      align-items: initial;
      opacity: 1;
      letter-spacing: 0;
    }
    .border-division{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      height: 0;
      border: 0.5px solid #342041;
      opacity: 1;
    }
  }
</style>
