<template>
    <div class="card-wrapper" :style="{'background-image': 'url(' + background + ')'}">

      <div @click="act1" :class="{'active1': active1}" class="about-card fist-c">
        <img class="icon-img" :src="img1" />
        <h4 class="hover-text"><span v-html="card1h1"></span> <span style="margin-left: 5px"><slot /></span></h4>
        <div class="border-division"></div>
        <p>Através de um preenchimento simples e online, forneces os teus dados pessoais, habilitações académicas e a tua experiência profissional. <br/> <b> Queremos conhecer-te!</b></p>
        <img :class="{'icon-card1':active1}" class="m-hide" :src="cardImg1" alt="" />
      </div>
      <div @click="act2" :class="{'active1': active2}" class="about-card two2">
        <img class="icon-img" :src="img2" />
        <h4 class="hover-text">{{card2h1}}</h4>
        <h4 class="unhover-text">Capacita-te!</h4>
        <div class="border-division"></div>
        <p>Esta formação em formato e-learning vai preparar-te para seres um Profissional de sucesso no mercado de trabalho. <br> <b>Capacita-te!</b> </p>
        <img :class="{'icon-card2':active2}" class="m-hide" :src="cardImg2" alt="" />
      </div>
      <div @click="act3" :class="{'active1': active3}"  class="about-card third-c tree3">
        <img class="icon-img" :src="img3" />
        <h4 class="hover-text">{{card3h1}}</h4>
        <h4 class="unhover-text">Supera-te!</h4>
        <div class="border-division"></div>
        <p>Através de uma entrevista Profissional e especializada, a equipa de recrutamento da RISE irá complementar o teu perfil, identificando competências, necessidades de formação, áreas de actividade e potencial de desenvolvimento. <br> <b>Supera-te!</b></p>
        <img :class="{'icon-card3':active3}" class="m-hide" :src="cardImg3" alt="" />
      </div>
      <div @click="act4" :class="{'active1': active4}"  class="about-card foth-c four4">
        <img class="icon-img" :src="img4" />
        <h4 class="hover-text">{{card4h1}}</h4>
        <h4 class="unhover-text">Boa sorte!</h4>
        <div class="border-division"></div>
        <p>As empresas interessadas no teu perfil irão entrar em contacto com a RISE WORK para saber mais sobre ti e agendar uma entrevista. <br> <b>Boa sorte!</b></p>
        <img :class="{'icon-card4':active4}" class="m-hide" :src="cardImg4" alt="" />
      </div>
    </div>
</template>

<script>
export default {
  name: 'AboutCardsTemplate',
  data () {
    return {
      active1: true,
      active2: false,
      active3: false,
      active4: false,
      selected: 1
    }
  },
  methods: {
    act1 () {
      this.active2 = false
      this.active3 = false
      this.active4 = false
    },
    act2 () {
      this.active2 = !this.active2
      if (this.active2) {
        this.active1 = true
      } else {
        this.active3 = false
        this.active4 = false
      }
    },
    act3 () {
      this.active3 = !this.active3
      if (this.active3) {
        this.active1 = true
        this.active2 = true
      } else {
        this.active4 = false
      }
    },
    act4 () {
      this.active4 = !this.active4
      if (this.active4) {
        this.active1 = true
        this.active2 = true
        this.active3 = true
      }
    }
  },
  props: {
    activeTogle: {
      type: Boolean
    },
    cardImg1: {
      type: String
    },
    cardImg2: {
      type: String
    },
    cardImg3: {
      type: String
    },
    cardImg4: {
      type: String
    },
    card1h1: {
      type: String
    },
    card1p: {
      type: String
    },
    card2h1: {
      type: String
    },
    card2p: {
      type: String
    },
    card3h1: {
      type: String
    },
    card3p: {
      type: String
    },
    card4h1: {
      type: String
    },
    card4p: {
      type: String
    },
    img1: {
      type: String
    },
    img2: {
      type: String
    },
    img3: {
      type: String
    },
    img4: {
      type: String
    },
    background: {
      type: String
    }
  }
}
</script>
<style lang="scss">
.about-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .unhover-text {
    color: #fff;
    font-size: 18px;
  }
  p {
    height: 136px;
  }
  .hover-text {
    min-height: 34px;
  }
  .hover-text, .unhover-text {
    margin: 2px 0;
    a {
      padding-bottom: 1px;
      text-decoration: none;
      color: inherit;
      border-bottom: 1px solid;
    }
  }
}
</style>
