<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52.688"
    height="52.687"
    viewBox="0 0 70.688 56.687"
  >
  <g :fill="color">
    <path
      class="a"
      d="M12.06,16.119a8.06,8.06,0,1,1,8.06-8.06A8.069,8.069,0,0,1,12.06,16.119Zm0-13.7A5.642,5.642,0,1,0,17.7,8.06,5.648,5.648,0,0,0,12.06,2.418Z"
      transform="translate(2.448)"
    />
    <path
      class="a"
      d="M14.911,26.508H1.209A1.209,1.209,0,0,1,0,25.3V19.657A7.666,7.666,0,0,1,7.657,12h13.7a1.209,1.209,0,0,1,0,2.418H7.657a5.245,5.245,0,0,0-5.239,5.239V24.09H14.911a1.209,1.209,0,0,1,0,2.418Z"
      transform="translate(0 7.343)"
    />
    <path
      class="a"
      d="M28.522,28.9h-13.7A2.824,2.824,0,0,1,12,26.075V18.821A2.824,2.824,0,0,1,14.821,16h13.7a2.824,2.824,0,0,1,2.821,2.821v7.254A2.824,2.824,0,0,1,28.522,28.9Zm-13.7-10.478a.4.4,0,0,0-.4.4v7.254a.4.4,0,0,0,.4.4h13.7a.4.4,0,0,0,.4-.4V18.821a.4.4,0,0,0-.4-.4Z"
      transform="translate(7.343 9.791)"
    />
    <path
      class="a"
      d="M23.463,19.948a1.209,1.209,0,0,1-1.209-1.209V16.321a.4.4,0,0,0-.4-.4h-4.03a.4.4,0,0,0-.4.4v2.418a1.209,1.209,0,1,1-2.418,0V16.321A2.824,2.824,0,0,1,17.821,13.5h4.03a2.824,2.824,0,0,1,2.821,2.821v2.418A1.209,1.209,0,0,1,23.463,19.948Z"
      transform="translate(9.179 8.261)"
    />
    <path
      class="a"
      d="M21.672,23.448a1.2,1.2,0,0,1-.519-.118L12.69,19.3a1.21,1.21,0,1,1,1.04-2.184L21.672,20.9l7.944-3.783a1.21,1.21,0,1,1,1.04,2.184l-8.463,4.03A1.209,1.209,0,0,1,21.672,23.448Z"
      transform="translate(7.343 10.403)"
    />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconProssional',
  props: {
    color: {
      type: String,
      default: () => '#fc0'
    },
    width: {
      type: String,
      default: () => '28'
    },
    height: {
      type: String,
      default: () => '35'
    }
  }
}
</script>
